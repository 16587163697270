<template>
  <div>
    <SpeedInsights />
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style scoped>
/* Add any styles scoped to App.vue here */
</style>
